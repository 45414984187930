<template>
  <div class="shop_list">
    <van-nav-bar
      title="店铺排行"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-tabs :active="active" sticky @change="changeTabs">
      <van-tab v-for="(v,k) in tablist" :key="k" :title="v.title">
        <div class="shop_day_data" v-for="(item, index) in list" :key="index">
          <van-cell :border="false">
            <template #icon>
              <div style="margin-right: 10px;">{{index+1}}</div>
            </template>
            <template #title>
              {{item.merchname}}<span @click="copay(item.bianhao)">【{{item.bianhao}}】</span>
            </template>
          </van-cell>
          <van-grid :column-num="3" :border="false">
            <van-grid-item icon="photo-o">
              <template #icon>
                <div class="grid_item_title">支付金额</div>
                <div class="grid_item_num">&yen;{{item.ordermoney ? item.ordermoney : 0}}</div>
              </template>
              <template #text>
                <div class="grid_item_text">昨日 &yen;{{item.ordermoney2 ? item.ordermoney2 : 0}}</div> 
              </template>
            </van-grid-item>
            <van-grid-item icon="photo-o">
              <template #icon>
                <div class="grid_item_title">订单数</div>
                <div class="grid_item_num">{{item.ordernum ? item.ordernum : 0}}</div>
              </template>
              <template #text>
                <div class="grid_item_text">昨日 {{item.ordernum2 ? item.ordernum2 : 0}}</div> 
              </template>
            </van-grid-item>
            <van-grid-item icon="photo-o">
              <template #icon>
                <div class="grid_item_title mb0">预估收益</div>
              </template>
              <template #text>
                <div class="grid_item_text hei">今日佣金：{{item.yongjin ? item.yongjin : 0}}</div>
                <div class="grid_item_text">昨日佣金：{{item.yongjin2 ? item.yongjin2 : 0}}</div>
                <div class="grid_item_text hei">今日差价：{{item.chajia ? item.chajia : 0}}</div>
                <div class="grid_item_text">昨日差价：{{item.chajia2 ? item.chajia2 : 0}}</div> 
              </template>
            </van-grid-item>
            <van-grid-item v-if="false">
              <template #icon>
                <div class="grid_item_member_title">新增游客：</div>
                <div class="grid_item_member_num">{{memberNum.newy ? memberNum.newy : 0}}</div>
                <div class="grid_item_member_title">新增会员：</div>
                <div class="grid_item_member_num">{{memberNum.newh ? memberNum.newh : 0}}</div>
              </template>
            </van-grid-item>
            <van-grid-item v-if="false">
              <template #icon>
                <div class="grid_item_member_title">昨日新增游客：</div>
                <div class="grid_item_member_num">{{memberNum.twonewy ? memberNum.twonewy : 0}}</div>
                <div class="grid_item_member_title">昨日新增会员：</div>
                <div class="grid_item_member_num">{{memberNum.twonewh ? memberNum.twonewh : 0}}</div>
              </template>
            </van-grid-item>
            <van-grid-item v-if="false">
              <template #icon>
                <div class="grid_item_member_title">全部游客：</div>
                <div class="grid_item_member_num">{{memberNum.ally ? memberNum.ally : 0}}</div>
                <div class="grid_item_member_title">全部会员：</div>
                <div class="grid_item_member_num">{{memberNum.allh ? memberNum.allh : 0}}</div>
              </template>
            </van-grid-item>
          </van-grid>
        </div>
      </van-tab>
    </van-tabs>
    <div style="width: 100%; height: 60px"></div>
  </div>
</template>
<script>
export default {
  name: "ShopList",
  data() {
    return {
      list: [],
      info: {},
      order: {},
      memberNum: {},
      active: 0,
      tablist: [
        {
          index: 0,
          title: '按佣金排序'
        },
        {
          index: 1,
          title: '按差价排序'
        }
      ]
    }
  },
  mounted() {
    console.log("dddd");
    this.init();
  },
  methods: {
    init() {
      console.log("sssssss");
      this.getlist();
    },
    onClickLeft() {
      this.$router.back();
    },
    // 获取前十名的排行
    getlist() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "shopsmobile/home/get_shop_list",
          _that.$qs.stringify({
            active: _that.active
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.list = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    changeTabs(name,title) {
      console.log(name, title);
      this.active = name;
      this.list = [];
      this.getlist();
    },

    // 复制
    copay(text) {
      if (text && text.length > 0) {
        let _that = null;
        _that = this;
        _that.$copyText(text).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
      }
    }
  },
}
</script>
<style lang="less">
.shop_list {
  min-height: 100vh;
  width: 100%;
  background-color: #f8f8f8;
  .van-nav-bar .van-icon {
    color: #333;
  }
  // 今日数据
  .shop_day_data {
    background-color: #fff;
    border-radius: 5px;
    margin: 10px;
    padding: 10px 10px 0;
    overflow: hidden;
    .van-cell {
      padding: 10px 0;
      .van-cell__title {
        flex: 1;
        text-align: left;
        font-weight: bold;
      }
    }
    .van-grid {
      .van-grid-item {
        .van-grid-item__content {
          padding: 10px 8px 16px 0;
        }
        &:last-of-type {
          .van-grid-item__content {
            padding: 10px 0;
          }
        }
      }
      .grid_item_title,
      .grid_item_num {
        margin-bottom: 12px;
        line-height: 24px;
        font-size: 14px;
        color: #333;
      }
      .grid_item_num {
        font-weight: 450;
        font-size: 14px;
      }
      .grid_item_member_title,
      .grid_item_member_num {
        line-height: 24px;
        font-size: 12px;
        color: #333;
      }
      .grid_item_member_num {
        font-weight: 450;
      }
      .grid_item_text {
        font-size: 12px;
        color: #999;
      }
      .hei {
        color: #333;
      }
      .mb0 {
        margin-bottom: 0;
      }
    }
  }
}
</style>